import { useEffect, useRef, useState } from 'react'
import { HelpContent } from './help'

import './App.css'
import { ReactComponent as Download } from './download.svg'

// eslint-disable-next-line
const getCookieByName = (name) => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
}
const setCookieByName = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
    const expires = `expires=${date.toUTCString()}`
    document.cookie = `${name}=${value};${expires};path=/`
}  
const getPythonData = async (url1,url2) => {
    const response = await fetch(`https://4python.site/?url1=${url1}&url2=${url2}`)
    const json = await response.json()
    return json
}
const getColor = percent => {
    if (percent < 10) {
        return 'green'
    } else if (percent >= 10 && percent < 40) {
        return 'yellow'
    } else {
        return 'red'
    }
}

const App = () => {

    const domain = 'webanalyzer.pro'
    let errorsList = []
    // const params = new URLSearchParams(window.location.search)
    const [userName,setUserName] = useState('')
    const [userID,setUserID] = useState(0)
    const [userAvailable,setUserAvailable] = useState(0)
    const [resultData,setResultData] = useState([])
    const [comparedSite,setComparedSite] = useState('')
    const [comparedList,setComparedList] = useState('')
    const [refID,setRefID] = useState('')

    const loader = useRef(null)
    const helpPopup = useRef(null)
    const resultBlockContent = useRef(null)

    const ShowLoader = () => loader.current.classList.add('active')
    const HideLoader = () => loader.current.classList.remove('active')

    const showHelpPopup = () => helpPopup.current.classList.add('active')
    const hideHelpPopup = () => helpPopup.current.classList.remove('active')

    const userNameChange = event => {
        setUserName(event.target.value)
    }
    const submitUserName = event => {
        event.preventDefault()
        ShowLoader()
        try {
            fetch(`https://${domain}/api.php?action=getuserdatabyname&username=${userName}`)
            .then(response => response.json())
            .then(json => {
                if (json.status === 200) {
                    setUserID(json.id)
                    setUserAvailable(json.available)
                    setComparedSite(json.url)
                    setComparedList(json.urllist.join('\n'))
                }
                if (json.status === 500) {
                    setUserID(0)
                    setUserAvailable(0)
                    setComparedSite('')
                    setComparedList('')
                }
            })
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }
    const comparedSiteChange = event => {
        const value = event.target.value.trim()
        setComparedSite(value)
        localStorage.setItem('first.site',value)
        ShowLoader()
        try {
            fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=url&value=${value}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => response.json())
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }
    const comparedListChange = event => {
        const value = event.target.value
        setComparedList(value)
        localStorage.setItem('website.list',value.trim())
        ShowLoader()
        try {
            fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=urllist&value=${value.split('\n').join('|')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => response.json())
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }
    }
    const compareSites = async (event) => {
        event.preventDefault()

        const websiteCompare = comparedSite.trim()
        // const websiteList = comparedList.trim().split('\n').filter(Boolean)

        const updatedList = comparedList.trim().split('\n').filter(Boolean).map(site => {
            const trimmedSite = site.trim()
            if (trimmedSite && !trimmedSite.startsWith('https://')) {
                return `https://${trimmedSite}`
            }
            return trimmedSite
        }).join('\n')
        setComparedList(updatedList)
        const websiteList = updatedList.split('\n')
        localStorage.setItem('website.list',updatedList)
        // console.log(websiteList)

        ShowLoader()
        try {
            fetch(`https://${domain}/api.php?action=savefield&id=${userID}&name=urllist&value=${updatedList.split('\n').join('|')}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                }
            })
            .then(response => response.json())
        } catch(error) {
            console.error(error)
        } finally {
            HideLoader()
        }

        ShowLoader()
        setResultData([])
        try {
            fetch(`https://${domain}/api.php?action=getavailablebyid&id=${userID}`)
            .then(resp => resp.json())
            .then(json => {
                if (parseInt(json.available) > 0) {
                    // Create an array to store all the promises
                    const allPromises = websiteList.map(site2 => {
                        return getPythonData(websiteCompare, site2)
                            .then(json => {
                                console.log(json)
                                if (json.status === 500) errorsList.push(json.error)
                                setResultData(prevResults => [...prevResults, json])
                            })
                            .catch(error => {
                                console.error(error)
                            })
                    })

                    Promise.all(allPromises).then(() => {
                        try {
                            fetch(`https://${domain}/api.php?action=minusone&id=${userID}&url=${comparedSite}&urllist=${comparedList.split('\n').join('|')}&errors=${errorsList.join('|')}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/x-www-form-urlencoded',
                                }
                            })
                            .then(response => response.json())
                            .then(data => {
                                const available = data.available
                                setUserAvailable(available)
                                setCookieByName('available',available,365)
                            })
                            .catch(error => {
                                console.error('Error:', error)
                            })
                        } catch(error) {
                            console.error(error)
                        } finally {
                            HideLoader()
                        }
                    })
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const copyClick = () => {
        if (resultBlockContent.current) {
          // Получаем HTML содержимое
          const content = resultBlockContent.current.innerHTML
          
          // Преобразуем HTML в текст с сохранением переносов строк
          const plainText = content
            .replace(/<br\s*\/?>/gi, '\n')
            .replace(/<\/p>/gi, '\n')
            .replace(/<[^>]+>/g, '')
    
          // Копируем текст в буфер обмена
          navigator.clipboard.writeText(plainText)
            .then(() => {})
            .catch((err) => {
              console.error('Ошибка при копировании: ', err);
            });
        }
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        const rValue = searchParams.get('r');
        if (rValue) {
            setRefID(rValue)
        }
    }, [])

    return (
        <>
            <div className="App">
                <div className="app_container">
                    <div className="app_header">
                        <form className="app_header_form" onSubmit={submitUserName}>
                            <input
                                type="text"
                                placeholder="User Name"
                                className="app_header_form_input"
                                value={userName}
                                onChange={userNameChange}
                            />
                            <button type="submit" className="app_header_form_submit">Auth</button>
                        </form>
                        <div className="app_header_info">
                            {userAvailable > 0 && `Проверок осталось: ${userAvailable}`}
                            {refID.trim() !== '' && <div>RefID: {refID}</div>}
                        </div>
                        <a
                            className="app_header_tg_button"
                            href="https://t.me/sales_knk"
                            target="_blank"
                            rel="nofollow noreferrer noopener"
                        >Пополнение<span className="hide_on_1024">&nbsp;и вопросы</span><span className="hide_on_750">: @sales_knk</span></a>
                    </div>
                    <form className="compared_site_form" onSubmit={compareSites}>
                        <input
                            className="compared_site_form_input"
                            value={comparedSite}
                            onChange={comparedSiteChange}
                            placeholder="Сайт для сравнения"
                            required
                        />
                        <button
                            type="submit"
                            className="compared_site_form_submit"
                            disabled={userAvailable < 1}
                        >Проверить</button>
                        <button
                            type="button"
                            className="get_help"
                            onClick={showHelpPopup}
                        >?</button>
                    </form>
                    <textarea
                        className="compared_list"
                        value={comparedList}
                        onChange={comparedListChange}
                        placeholder="Список сайтов"
                    ></textarea>
                    <div className="result_block">
                        <div className="result_block_content" ref={resultBlockContent}>
                        {resultData.map((e, i) => (
                            e.status === 200 ? (
                            <p key={`resultid${i}`}>
                                Сводный индекс сходства между<br />
                                <a href={e.url1} target="_blank" rel="nofollow noopener noreferrer">{e.url1}</a>
                                <br />
                                <a href={e.url2} target="_blank" rel="nofollow noopener noreferrer">{e.url2}</a>
                                <br />
                                Составляет <span className={getColor(e.total)}>{e.total}%</span><br />
                                <a download href={e.diff_links?.html_diff} className="app_download"><Download /></a> Сходство HTML <span className={getColor(e.html)}>{e.html}%</span><br />
                                <a download href={e.diff_links?.css_diff} className="app_download"><Download /></a> Сходство CSS <span className={getColor(e.css)}>{e.css}%</span><br />
                                <a download href={e.diff_links?.js_diff} className="app_download"><Download /></a> Сходство JS <span className={getColor(e.js)}>{e.js}%</span>
                            </p>
                            ) : (
                            <p key={`resultid${i}`} className="app_error">Error: {e.error}</p>
                            )
                        ))}
                        </div>
                        <button className="app_copy" onClick={copyClick} type="button">&#128203;</button>
                    </div>
                </div>
                <div className="loader" ref={loader}></div>
            </div>
            <div className="help_popup" ref={helpPopup}>
                <HelpContent />
                <button
                    className="help_popup_content_close"
                    onClick={hideHelpPopup}
                ></button>
                <div
                    className="help_popup_overlay"
                    onClick={hideHelpPopup}
                ></div>
            </div>
        </>
    )
}

export default App
